import "select2";

document.addEventListener("turbo:load", function() {
  $('[data-s2]').select2();

  const $tags = $('[data-s2-tags]');
  const placeholder = $tags.data("placeholder") || "Choisir un élément"

  $tags.select2({
    maximumSelectionLength: 3,
    placeholder: placeholder
  });

  // Add tags with the order in which they have been selected
  $tags.on('select2:select', function(e) {
    var id = e.params.data.id;
    var option = $(e.target).children('[value="'+id+'"]');
    option.detach();
    $(e.target).append(option).trigger('change');
  });
});