import bsCustomFileInput from 'bs-custom-file-input'
import { DirectUpload } from "@rails/activestorage"
import Dropzone from 'dropzone'

document.addEventListener("turbo:load", function() {
  bsCustomFileInput.init();
  handleDropzones();
})

function handleDropzones () {
  document.querySelectorAll('.drop-zone').forEach((container) => {
    let filesToProcess = new Array()
    const activeStorageBlobExtractor = /\/(?:blobs|representations)\/(?:redirect\/){0,1}((\w|\-)*)/
    const urls = Array.from(container.dataset.files.split(',')).filter((url) => !!url)

    const dropzone = new Dropzone(container, {
      paramsName: container.dataset.name,
      autoProcessQueue: false,
      addRemoveLinks: true,
      dictDefaultMessage: container.dataset.placeholder,
      dictFallbackMessage: "Ton navigateur ne prend pas en charge les téléchargements de fichiers par glisser-déposer.",
      dictFallbackText: "Utilise le formulaire de secours ci-dessous pour télécharger tes fichiers comme au bon vieux temps.",
      dictFileTooBig: "Ton fichier est trop lourd ({{filesize}}MiB) ! La taille maximale autorisée est de : {{maxFilesize}}MiB.",
      dictInvalidFileType: "Tu ne peux pas télécharger des fichiers de ce type.",
      dictResponseError: "Le serveur a répondu avec le code {{statusCode}}.",
      dictCancelUpload: "Annuler",
      dictCancelUploadConfirmation: "Es-tu sûr de vouloir annuler ce téléchargement ?",
      dictRemoveFile: "Retirer",
      dictMaxFilesExceeded: "Tu ne peux pas ajouter plus de 3 fichiers.",
      maxFiles: 3,
      url: '/',
      acceptedFiles: "image/*"
    });

    urls.forEach((url) => {
      dropzone.displayExistingFile({ name: url.split('/').pop() }, url)
      const input = document.createElement('input')
      input.type = "hidden"
      input.value = url.match(activeStorageBlobExtractor)[1]
      input.name = container.dataset.name
      container.appendChild(input)
    });

    dropzone.on('addedfile', (file) => {
      filesToProcess.push(file)
    });

    dropzone.on('thumbnail', (file, dataURL) => {
      if (filesToProcess.includes(file)) return
      const sizeElement = file.previewElement.querySelector('.dz-size [data-dz-size]')
      sizeElement.style.display = 'none'
    })

    dropzone.on('removedfile', (file) => {
      filesToProcess = filesToProcess.filter((f) => f !== file)
      const blobSignedId = file.dataURL.match(activeStorageBlobExtractor)[1]
      container.querySelector(`input[value="${blobSignedId}"]`).remove()
    });

    dropzone.on('maxfilesexceeded', (file) => {
      this.removeFile(file);
    });

    const form = container.closest('form')
    const fileInputsForDirectUpload = form.querySelectorAll('input[data-direct-upload-url]')

    const submitLtn = function (e) {
      processFiles(e, filesToProcess, fileInputsForDirectUpload, container)
      form.removeEventListener('submit', this)
    }

    form.addEventListener('submit', submitLtn)
  });

  function processFiles (e, filesToProcess, fileInputsForDirectUpload, container) {
    if (filesToProcess.length == 0) return
    filesToProcess = filesToProcess.slice(0, 3);

    e.preventDefault()
    let queueSize = filesToProcess.length
    const submitButtons = e.target.querySelectorAll('input[data-disable-with]')
    submitButtons.forEach((button) => button.disabled = true)
    container.classList.add('lock')

    fileInputsForDirectUpload.forEach((input) => {
      input.removeAttribute('data-direct-upload-url')
    })

    container.querySelector('.dz-upload-alert').classList.remove('d-none')

    filesToProcess.forEach((file) => {
      const directUpload = new DirectUpload(file, container.dataset.url)

      directUpload.create((error, blob)=>{
        if (!error) {
          const input = document.createElement('input')
          input.type = 'hidden'
          input.value = blob.signed_id
          input.name = container.dataset.name
          container.appendChild(input)
        }

        if (0 === --queueSize) {
          fileInputsForDirectUpload.forEach((input) => {
            input.setAttribute('data-direct-upload-url', container.dataset.url)
          })
          e.target.submit()
        }
      })
    })
  }

}
