import textFit from 'textfit';

document.addEventListener("turbo:load", function() {
  const elements = document.querySelectorAll('[data-fit-text]');
  elements.forEach((element) => {
    textFit(element, {
      alignVert: true,
      maxFontSize: 48,
      multiLine: true
    });
  });
});