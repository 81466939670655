window.initGoogleMap = function () {
  if (!window.google) return;

  var input = document.querySelector("input[data-location]");

  if (input) {
    var options = {
      fields: ["name"],
      types: ["(cities)"],
    };
    new window.google.maps.places.Autocomplete(input, options);
  }

};

document.addEventListener("turbo:load", function() {
  initGoogleMap();
});