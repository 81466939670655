import Rails from '@rails/ujs'

export default class Bridge {
  static register(token, platform) {
    fetch("/notification_tokens", {
      body: JSON.stringify({ token: token, platform: platform }),
      method: "POST",
      credentials: 'same-origin',
      headers: {
        "Content-Type": "application/json",
        "X-CSRF_Token": Rails.csrfToken()
      },
    });
  }
}
