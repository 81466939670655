import * as timeago from 'timeago.js'
import { fr } from 'timeago.js/lib/lang'

timeago.register('fr', fr)

document.addEventListener("turbo:load", function() {
  const nodes = document.querySelectorAll('time[data-timeago]')

  if (nodes.length) {
    timeago.render(nodes, 'fr')
    timeago.cancel()
  }
})
