import { Tooltip } from 'bootstrap'

document.addEventListener("turbo:load", function() {

  $('#profiles_filters select, #profiles_filters input[type=radio]').on('change', function(e){
    this.form.submit();
  })

  $('#more_tags_form').on('click', function(event) {
  	event.stopPropagation();
  });

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
  });

})
