document.addEventListener("turbo:load", function() {

  $("[data-form-prepend]").click(function(e) {
    const obj = $(this.dataset.formPrepend)
    const time = new Date().getTime()

    obj.find("input, select, textarea").each(function() {
      this.name = this.name.replace("new_record", time)
      this.id = this.id.replace("new_record", time)
    })
    obj.insertBefore(this)

    return false
  })

  $('form').on('click', '[data-form-fieldset-remove]', function(e) {
    e.preventDefault()

    const $fieldset = $(this).closest('fieldset')
    $fieldset.find('input[type=hidden]').val('1')
    $fieldset.hide()
  })
})
