import Bugsnag from "@bugsnag/js";

if (process.env.BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: process.env.BUGSNAG_API_KEY,
    appVersion: process.env.HEROKU_RELEASE_VERSION,
    beforeSend: function (report) {
      const params = new URLSearchParams(location.search);
      const fbclidCheck = params.get("fbclid");

      if (
        fbclidCheck &&
        report.errorClass === "TypeError" &&
        report.errorMessage === "Illegal invocation"
      ) {
        report.ignore();
      }
    },
  });
}
