import Rails from '@rails/ujs';

document.addEventListener("turbo:load", function() {
  const cachedCountryCode = getCountryCodeFromCache();
  if (!cachedCountryCode) {
    fetchAndUpdateCountryCode();
  }
});

function handleGeolocation(position) {
  const data = {
    'latitude': position.coords.latitude,
    'longitude': position.coords.longitude
  };
  fetchCountryCode(data);
}

function handleGeolocationError() {
  fetchCountryCode();
}

function fetchAndUpdateCountryCode() {
  if ("geolocation" in navigator) {
    // Check for geolocation permissions
    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
        if (permissionStatus.state === 'granted') {
          navigator.geolocation.getCurrentPosition(position => {
            handleGeolocation(position);
          });
        } else if (permissionStatus.state === 'denied') {
          handleGeolocationError();
        } else if (permissionStatus.state === 'prompt') {
          navigator.geolocation.getCurrentPosition(
            position => handleGeolocation(position),
            err => handleGeolocationError()
          );
        }
      });
    } else {
      // Fallback when permissions API isn't available
      navigator.geolocation.getCurrentPosition(
        position => {
          handleGeolocation(position);
        },
        err => {
          // Handle error (e.g., user denied geolocation request)
          handleGeolocationError();
        }
      );
    }
  } else {
    handleGeolocationError();
  }
}

function saveCountryCodeToCache(countryCode) {
  try {
    const data = {
      countryCode: countryCode,
      timestamp: Date.now()
    };
    localStorage.setItem('countryData', JSON.stringify(data));
  } catch (error) {
    console.error("Error saving to localStorage", error);
  }
}

function getCountryCodeFromCache() {
  try {
    const SEVEN_DAYS = 7 * 24 * 60 * 60 * 1000;
    const data = JSON.parse(localStorage.getItem('countryData'));
    if (data && (Date.now() - data.timestamp) <= SEVEN_DAYS) {
      return data.countryCode;
    }
  } catch (error) {
    console.error("Error retrieving from localStorage", error);
  }
  return null;
}

function fetchCountryCode(data = null) {
  const requestConfig = {
    type: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
    url: '/geolocations/determine_country',
    success: (result) => {
      if (result.countryCode) {
        saveCountryCodeToCache(result.countryCode)
      }
    }
  };

  // If data is provided, add it to the request config
  if (data) {
    requestConfig.data = new URLSearchParams(data).toString();
  }

  Rails.ajax(requestConfig);
}
