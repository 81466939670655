document.addEventListener("turbo:load", function () {
  $(".faq-detail-title").click(function (e) {
    const faqContent = $(this).parent().find(".faq-detail-content")
    const faqPlus = $(this).parent().find(".faq-plus")
    const faqMinus = $(this).parent().find(".faq-minus")
    if (faqContent.hasClass("d-none")) {
      faqContent.removeClass("d-none")
      faqMinus.removeClass("d-none")
      faqPlus.addClass("d-none")
    } else {
      faqContent.addClass("d-none")
      faqPlus.removeClass("d-none")
      faqMinus.addClass("d-none")
    }
  });

  $(".module-item-header").click(function (e) {
    const moduleContent = $(this).parent().find(".module-item-content")
    const plus = $(this).parent().find(".plus")
    const minus = $(this).parent().find(".minus")
    if (moduleContent.hasClass("d-none")) {
      moduleContent.removeClass("d-none")
      minus.removeClass("d-none")
      plus.addClass("d-none")
    } else {
      moduleContent.addClass("d-none")
      plus.removeClass("d-none")
      minus.addClass("d-none")
    }
  })

  $(".curriculum-header").click(function (e) {
    const curriculumContent = $(this).parent().find(".curriculum-content")
    const arrowDown = $(this).parent().find(".arrow-down")
    const arrowUp = $(this).parent().find(".arrow-up")
    if (curriculumContent.hasClass("d-none")) {
      curriculumContent.removeClass("d-none")
      arrowUp.removeClass("d-none")
      arrowDown.addClass("d-none")
    } else {
      curriculumContent.addClass("d-none")
      arrowDown.removeClass("d-none")
      arrowUp.addClass("d-none")
    }
  })

  $('.owl-carousel-two').owlCarousel({
    loop: true,
    margin: 30,
    nav: true,
    center: true,
    dots: false,
    stagePadding: 15,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    items: 2,
    responsive: {
      0: {
        items: 1,
        dots: true,
        nav: false
      },
      767: {
        items: 1,
        dots: true,
        nav: false
      },
      892: {
        items: 1,
        stagePadding: 150,
        dots: true,
        nav: false
      },
      1100: {
        items: 2
      }
    }
  });

  $('.owl-carousel-three').owlCarousel({
    loop: true,
    margin: 30,
    nav: true,
    center: true,
    dots: false,
    stagePadding: 15,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    items: 3,
    responsive: {
      0: {
        items: 1,
        dots: true,
        nav: false
      },
      767: {
        items: 1,
        dots: true,
        nav: false
      },
      892: {
        items: 1,
        stagePadding: 150,
        dots: true,
        nav: false
      },
      1100: {
        items: 3
      }
    }
  });

  $(".payment-choice-bloc").click(function (e) {
    const radioContainer = $(this).parent()
    const radio = $(this).parent().find("input")

    if (radio[0].checked) {
      $(".active-payment").removeClass("active-payment")
      radioContainer.addClass("active-payment")
    }
  })

  capitalizeFirstLetters()

  function capitalizeFirstLetters() {
    let textToCap = []
    let textCap = ""
    if (!($(".first-cap") === undefined)) {
      if ($(".first-cap").length > 1) {

        for (var h = 0; h < $(".first-cap").length; h++) {
          textToCap.push($(".first-cap")[h])
        }

        for (var i = 0; i < textToCap.length; i++) {
          textToCap[i] = textToCap[i].innerText.split(" ")

          for (var j = 0; j < textToCap[i].length; j++) {
            textToCap[i][j] = textToCap[i][j].charAt(0).toUpperCase() + textToCap[i][j].slice(1)
          }
          textCap = textToCap[i].join(" ")

          $(".first-cap")[i].innerHTML = textCap
        }
      } else {
        for (var i = 0; i < textToCap.length; i++) {
          textToCap[i] = textToCap[i].charAt(0).toUpperCase() + textToCap[i].slice(1)
        }
        textCap = textToCap.join(" ")
        $(".first-cap").innerHTML = textCap
      }
    }
  }

  function makeTimer(counter) {
    // var endTime = new Date("5 may 2023 10:20:00 GMT+01:00");
    let date = $(counter).find(".counter-date")[0].innerText
    var endTime = new Date(date.split("UTC")[0].trim());
    endTime = (Date.parse(endTime) / 1000);

    var now = new Date();
    now = (Date.parse(now) / 1000);

    var timeLeft = endTime - now;

    var days = Math.floor(timeLeft / 86400);
    var hours = Math.floor((timeLeft - (days * 86400)) / 3600);
    var minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600)) / 60);
    var seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));

    if (hours < "10") { hours = "0" + hours; }
    if (minutes < "10") { minutes = "0" + minutes; }
    if (seconds < "10") { seconds = "0" + seconds; }

    $($(counter).find("#days")[0]).html(days);
    $($(counter).find("#hours")[0]).html(hours);
    $($(counter).find("#minutes")[0]).html(minutes);
    $($(counter).find("#seconds")[0]).html(seconds);
  }

  var allCounters = $(".counter-container")
  if (allCounters?.length > 0) {
    allCounters?.map((index, counter) => {
      var interval = setInterval(function () {
        makeTimer(counter);
        let date = $(counter).find(".counter-date")[0].innerText
        if (new Date(date.split("UTC")[0].trim()) < new Date()) {
          clearInterval(interval)
          $($(counter).find("#days")[0]).html("0");
          $($(counter).find("#hours")[0]).html("00");
          $($(counter).find("#minutes")[0]).html("00");
          $($(counter).find("#seconds")[0]).html("00");
        }
      }, 1000);
    })
  }

  // TODO: Hide crisp chat when user is on mobile and make sure they can contact us another way
  // if (window.innerWidth <= 768) { // assuming 768px as the breakpoint for mobile
  //   $crisp.push(["do", "chat:hide"]);
  // }

  $('#talents_select_tag2').select2({
    dropdownParent: $('#FilterModal')
  });
  $('#location_select_tag2').select2({
    dropdownParent: $('#FilterModal')
  });
})
