import Rails from '@rails/ujs';
import { displaySpinner, hideSpinner } from './packs/application';
import { flashAlert } from 'orders'

document.addEventListener("turbo:load", () => {
  handleOrderButtons();

  document.querySelectorAll('input[type=radio][name=payment_method]').forEach(function (radio) {
    radio.addEventListener('change', function () {
      document.querySelector('.order-button-mobile-money').classList.toggle("d-none");
      document.querySelector('.order-button-credit-card').classList.toggle("d-none");
    });
  });

  $('body').on('click', '#mobile-money-payment-link, #credit-card-payment-link', function (e) {
    e.preventDefault();
  });
});

function handleOrderButtons() {
  document.querySelectorAll('.order-button').forEach((button) => {
    const data = {
      'product_id': button.dataset.productId,
      'product_type': button.dataset.productType
    };

    if (!data) return;
    button.disabled = true;

    Rails.ajax({
      type: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()
      },
      url: '/orders/payment_details',
      data: new URLSearchParams(data).toString(),
      success: (result) => handleOrderButton(button, result),
      error: (result) => flashAlert(result.notice)
    });
  });
}

function handleOrderButton(button, result) {
  button.removeAttribute('disabled');
  const spinner = button.querySelector('.spinner-border');
  const productType = button.dataset.productType;

  button.addEventListener('click', (e) => {
    e.preventDefault();
    if (spinner) {
      displaySpinner(spinner);
    }

    if (button.classList.contains('order-button-credit-card')) {
      initCreditCardPayment(result, productType);
    } else if (button.classList.contains('order-button-mobile-money')) {
      initMobileMoneyPayment(result, productType);
    }
  });
}

function initCreditCardPayment(result, productType) {
  createNewOrder(result, productType, 'credit-card').then((data) => {
    if (data.status === 'success') {
      if (result.variant) {
        var variantProductDetails = {
          variant_product_id: result.variant.product_id,
          variant_product_type: result.variant.product_type
        }
      }

      redirectToPaymentPage(
        productType,
        result.product.id,
        "credit-card",
        data.orderId,
        result.variant ? variantProductDetails : null
      );
    }
  });
}

function initMobileMoneyPayment(result, productType) {
  createNewOrder(result, productType, 'mobile-money').then((data) => {
    if (data.status === 'success') {
      if (result.variant) {
        var variantProductDetails = {
          variant_product_id: result.variant.product_id,
          variant_product_type: result.variant.product_type
        }
      }

      redirectToPaymentPage(
        productType,
        result.product.id,
        "mobile-money",
        data.orderId,
        result.variant ? variantProductDetails : null
      );
    }
  });
}

export function createNewOrder(result, productType, paymentMethod) {
  const data = {
    order: {
      payment_method: paymentMethod,
      product_type: productType,
      product_id: result.product.id,
      user_id: result.customer.id
    },
    user: {
      email: result.customer.email,
      lastname: result.customer.lastname,
      firstname: result.customer.firstname,
      stripe_customer_id: result.customer.stripe_customer_id
    }
  };

  return submitOrder('/orders', data);
}

export function redirectToPaymentPage(productType, productId, paymentMethod, orderId, variantProductDetails = null) {
  let redirectUrl;
  switch (productType) {
    case 'Event':
      redirectUrl = `/events/${productId}/charge/${paymentMethod}?order_id=${orderId}`;
      break;
    case 'Variant':
      const variantProductId = variantProductDetails.variant_product_id;
      const variantProductType = variantProductDetails.variant_product_type;
      if (variantProductType === 'Challenge') {
        redirectUrl = `/programs`;
      } else if (variantProductType === 'Resource') {
        redirectUrl = `/resources`;
      }

      redirectUrl = `${redirectUrl}/${variantProductId}/charge/${paymentMethod}?order_id=${orderId}&variant_id=${productId}`;
      break;
    case 'Resource':
      redirectUrl = `/resources/${productId}/charge/${paymentMethod}?order_id=${orderId}`;
      break;
    default:
      throw new Error('Product type not supported');
  }

  Turbo.visit(window.location.origin + redirectUrl);
}

function submitOrder(action, data) {
  return fetch(action, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
    body: JSON.stringify(data)
  }).then((result) => result.json());
}
