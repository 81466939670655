document.addEventListener("turbo:load", function () {
  $('[id$=_all_categories]').on('change', function (e) {
    if (this.checked) {
      $(this).closest('form').find('input[type=checkbox]').not(this).prop('checked', false);
    }
  });

  $('.resources_filters').on('change', 'input[type=radio], input[type=checkbox]', function (e) {
    var form = $(this).closest('form');
    if (this.type === 'checkbox' && this.value !== 'all') {
      form.find('[id$=_all_categories]').prop('checked', false);
    }
    form.submit();
  });

  $('.challenges_filters, .events_filters').on('change', 'input[type=radio], input[type=checkbox]', function (e) {
    var form = $(this).closest('form');
    form.submit();
  });
});
