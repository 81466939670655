import InfiniteScroll from "infinite-scroll"

document.addEventListener("turbo:load", function() {
  const element = document.querySelector(".infinite-scroll");
  const nextElement = document.querySelector(".pagination a[rel=next]");

  if (!element || !nextElement) {
    return
  }

  let infScroll = new InfiniteScroll(element, {
    append: ".infinite-scroll-item",
    hideNav: ".pagination",
    path: ".pagination a[rel=next]",
    status: '.infinite-scroll-status',
    onInit: function () {
      document.querySelector('.infinite-scroll-status').classList.remove('d-none')
    }
  })
})
