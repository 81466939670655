import mixpanel from "mixpanel-browser";

if (process.env.MIXPANEL_TOKEN) {
  mixpanel.init(process.env.MIXPANEL_TOKEN);

  if (process.env.MIXPANEL_DEBUG) {
    mixpanel.set_config({ debug: true });
  }

  document.addEventListener("turbo:load", function (e) {
    if (!window.analytics) {
      return;
    }

    if (!e.detail.timing.visitStart) {
      var people = window.analytics.people;
      if (people) {
        mixpanel.identify(people.id);
        mixpanel.people.set({
          $avatar: people.avatar,
          $created: people.created_at,
          $email: people.email,
          $name: people.name,
          $phone: people.phone,
          admin: people.admin,
          paid: people.subscribed,
          stars: people.stars
        });
      }
    }

    var events = window.analytics.events;
    if (events) {
      events.forEach(function (event) {
        mixpanel.track(event.name, Object.assign({}, event.properties));
      });
    }
    window.analytics.events = [];
  });
}
