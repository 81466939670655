document.addEventListener("turbo:load", function () {
  $('.post-content').readmore({
    lessLink: '',
    moreLink: '<a href="#" class="my-2">Lire plus</a>',
    collapsedHeight: 400,
    heightMargin: 16,
  });

  window.addEventListener("trix-file-accept", function (event) {
    const maxFileSize = 1024 * 1024 * 5; // 5MB
    if (event.file.size > maxFileSize) {
      event.preventDefault();
      alert("Les fichiers joints ne peuvent pas dépasser 5 Mo.!");
    }
  });

  $('body').on('click', '.challenge-chapter', function (e) {
    $(this).find(".show_more").toggleClass('d-none');
    $(this).find(".show_less").toggleClass('d-none');
  });

  var bilan_container = document.querySelector(".bilan-container");
  if (bilan_container) {
    bilan_container.querySelector('.submit-btn').addEventListener('click', function (e) {
      e.preventDefault();
      this.disabled = true;
      this.querySelector('.spinner').style.display = 'inline-block';
      bilan_container.submit();
    });
  }

});
