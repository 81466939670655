import Rails from '@rails/ujs';
import { displaySpinner, hideSpinner } from './packs/application';
import { createNewOrder, redirectToPaymentPage } from './orders_init';
import { flashAlert } from './orders';

document.addEventListener("turbo:load", () => {
  handlePromoCodeApplication();
  updateSelectedVariant();
});

function handlePromoCodeApplication() {
  const applyPromoCodeButton = document.querySelector('#apply-promo-code');
  if (applyPromoCodeButton) {
    const spinner = applyPromoCodeButton.querySelector('.spinner-border');

    applyPromoCodeButton.addEventListener('click', function () {
      let promoCode = document.querySelector('#promo-code').value;
      let orderId = document.querySelector('input[name="order-id"]').value;
      displaySpinner(spinner); // Show the spinner
      applyPromoCodeButton.disabled = true; // Disable the button

      if (promoCode) {
        fetch('/orders/apply_promo_code', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
          },
          body: JSON.stringify({
            promo_code_value: promoCode,
            order_id: orderId
          })
        })
          .then(response => response.json())
          .then(result => {
            hideSpinner(spinner); // Hide the spinner
            applyPromoCodeButton.disabled = false; // Enable the button
            if (result.error) {
              $('#promo-code-result').html('Erreur en appliquant le code promo : ' + result.error);
              $('#promo-code-result').removeClass('text-success');
              $('#promo-code-result').addClass('text-danger');
            } else {
              document.querySelector('#order-price-display').outerHTML = result.price_html;
              $('#promo-code-result').html("Bravo ! Tu bénéficies de " + result.data.percentage + "% de réduction");
              $('#promo-code-result').addClass('text-success');
              $('#promo-code-result').removeClass('text-danger');
            }
          });
      } else {
        spinner.style.display = 'none'; // Hide the spinner
        applyPromoCodeButton.disabled = false; // Enable the button
        $('#promo-code-result').html("As-tu oublié d'entrer ton code promo ?");
        $('#promo-code-result').removeClass('text-success');
        $('#promo-code-result').addClass('text-danger');
      }
    });
  }
}

function updateSelectedVariant() {
  const variantSelect = document.querySelector('select[name="variant-id"]');
  if (variantSelect) {
    variantSelect.addEventListener('change', function () {
      const selectedVariant = variantSelect.options[variantSelect.selectedIndex].value;
      const data = {
        'product_id': selectedVariant,
        'product_type': variantSelect.dataset.productType
      };

      if (!data) return;
      variantSelect.disabled = true;
      const paymentMethod = document.querySelector('input[name="payment-method"]').value;

      Rails.ajax({
        type: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': Rails.csrfToken()
        },
        url: '/orders/payment_details',
        data: new URLSearchParams(data).toString(),
        success: (result) => findOrCreateOrder(result, variantSelect.dataset.productType, paymentMethod),
        error: (result) => flashAlert(result.notice)
      });
    });
  }
}

function findOrCreateOrder(result, productType, paymentMethod) {
  createNewOrder(result, productType, paymentMethod).then((data) => {
    if (data.status === 'success') {
      if (result.variant) {
        var variantProductDetails = {
          variant_product_id: result.variant.product_id,
          variant_product_type: result.variant.product_type
        }
      }
      redirectToPaymentPage(
        productType,
        result.product.id,
        paymentMethod,
        data.orderId,
        result.variant ? variantProductDetails : null
      );
    }
  });
}
