document.addEventListener("turbo:load", function() {

  $('input[data-image-preview]').on('change', function (e) {
    var file = e.target.files[0]
    if (file) {
      var image = document.querySelector(e.target.dataset.imagePreview);
      var width = image.parentElement.clientWidth;
      image.src = URL.createObjectURL(file);
      image.style.width = `${width}px`;
      image.style.height = `${width}px`;
      image.style.display = 'inline';
    }
  });

});