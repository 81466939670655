document.addEventListener("turbo:load", function() {
  document.querySelectorAll('div[data-comment-reply]').forEach(function (element) {
    element.addEventListener('shown.bs.collapse', function () {
      const elementRect = element.getBoundingClientRect()

      element.querySelector('textarea').focus()

      if (elementRect.top > window.innerHeight) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest'
        })
      }
    })

    element.addEventListener('hide.bs.collapse', function (e) {
      e.preventDefault()
    })
  });

  $(".read-more-comment").on('click', function(e) {
    e.preventDefault();
    $(this).parent().html($(this).data("text"));
  });

  $('.comment-body').readmore({
    lessLink: '',
    moreLink: '<a href="#" class="my-2">Lire plus</a>',
    collapsedHeight: 125,
    heightMargin: 16,
  });

});
