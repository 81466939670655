// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");

require("chartkick");
require("chart.js");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import { Turbo } from "@hotwired/turbo-rails";
window.Turbo = Turbo;

import "jquery";
import "bootstrap";
import "analytics";
import "autolink";
import "challenges";
import "comments";
import "errors";
import "products";
import "files";
import "image_preview";
import "location";
import "nested_attributes";
import "orders";
import "orders_init";
import "orders_update";
import "pagination";
import "profiles";
import "readmore";
import "selects";
import "tel_input";
import "text_fit";
import "time";
import 'owl.carousel';
import "general";
import "geolocation";

import Bridge from "bridge"
window.bridge = Bridge

require("trix");
require("@rails/actiontext");

export function displaySpinner(spinner_element) {
  spinner_element.style.display = 'inline-block';
}

export function hideSpinner(spinner_element) {
  spinner_element.style.display = 'none';
}
